import Base from './Base';
import WPDarkMode from "./WPDarkMode";
import './DarkModeAuto'

class Color extends Base {

    // Fires when the DOM is ready
    ready() {
        // console.log('Color Ready');
        this.always();

        // When automatic mode is enabled
        if ( this.isAutoColor ) {
            const html = document.querySelector('html');

            html?.setAttribute('data-wp-dark-mode-loading', true);
            this.applyAutoDark();
            html?.removeAttribute('data-wp-dark-mode-loading');
        } else {
            // Colors need to be fixed when Automatic Mode is disabled
            this.applyPreset();
        }
    }

    /**
     * Do always
     */
    always() {
        // Removed loading attribute from html tag
        const html = document.querySelector('html');

        html?.removeAttribute('data-wp-dark-mode-loading');

        this.handleIncludes();
        this.HandleExcludes();
    }

    /**
     * When Automatic Mode is enabled
     *
     * @return {void}
     */
    async applyAutoDark(){
        const FiltersConfig = {
            brightness: wp_dark_mode_json.options.color_filter_brightness || 100,
            contrast: wp_dark_mode_json.options.color_filter_contrast || 90,
            sepia: wp_dark_mode_json.options.color_filter_sepia || 10,
            grayscale: wp_dark_mode_json.options.color_filter_grayscale || 0,
        }

        if ( WPDarkMode.isActive ) {
            DarkModeAuto.enable(FiltersConfig, {
                ignoreInlineStyle: '.wp-dark-mode-ignore'
            });
        }

        WPDarkMode.onChange(() => {
            // console.log('Dark Mode Changed');
            if ( WPDarkMode.isActive ) {
                DarkModeAuto.enable(FiltersConfig, {
                    ignoreInlineStyle: '.wp-dark-mode-ignore'
                });
            } else {
                DarkModeAuto.disable();
            }
        })
    }



    // Get preset id
    get presetId() {
        const target = typeof wp_dark_mode_admin_json !== 'undefined' ? wp_dark_mode_admin_json : wp_dark_mode_json;
        return target?.options?.color_preset_id || false;
    }

    /**
     * Is Automatic Color.
     *
     * @return {boolean}
     */
    get isAutoColor() {
        return Number(this.presetId) === 0;
    }

   
    /**
     * Apply colors to the elements
     * 
     * @param {boolean} isDark 
     * @returns 
     */
    initHandlers() {
    }

    /**
     * Fix colors
     *
     */
    applyPreset(){
        const all = document.querySelectorAll(`body *:not(.wp-dark-mode-ignore), body *:not(.wp-dark-mode-ignore *)`);
        // window.Log('all', all)

        if ( ! all || ! all.length) return;

        all.forEach(element => {

            // Add class .wp-dark-mode-background
            element.classList.add('wp-dark-mode-transparent');

            // Is excludable.
            if ( this.shouldIgnore(element) ) {

                const backgroundColor = window.getComputedStyle(element).backgroundColor || 'rgba(0, 0, 0, 0)';

                // Log('Background Color', backgroundColor) 

                if ( ! this.isTransparent( backgroundColor ) ) {
                    element.classList.add('wp-dark-mode-ignore');
                }

                // Log('Should Ignore', element, backgroundColor)
                element.classList.add('wp-dark-mode-transparent');

            } else {
                element.classList.remove('wp-dark-mode-transparent');
            }
        })
    }

	/**
	 * Handle excludes
	 */
	HandleExcludes() {
        const excludes  = (
            wp_dark_mode_json.options.excludes_elements ? wp_dark_mode_json.options.excludes_elements + ',.wp-dark-mode-ignore' : '.wp-dark-mode-ignore'
            ) + ( wp_dark_mode_json.excluded_elements ? ', ' + wp_dark_mode_json.excluded_elements : '' );
		const ignorableElements = document.querySelectorAll( excludes );

		if ( ! ignorableElements || ! ignorableElements.length) return;

		ignorableElements.forEach(element => {
			const ignoreRecursively = el => {

				// Bail, if element is not valid.
				if ( ! el ) return;

				// If el not intance of html.
				if ( ! (el instanceof HTMLElement) ) {
					return;
				}

                // If element has class .wp-dark-mode-include, return;
                if ( el.classList.contains('wp-dark-mode-include') ) return;

				// Add class.
				el.classList.add('wp-dark-mode-ignore');

				// if has background color, make it important.
				const { color, borderColor } = window.getComputedStyle(el) || {};

				// Log('backgroundColor', backgroundColor, color, borderColor);
				
				// If has color, border color, make it important.
				if ( color !== 'rgba(0, 0, 0, 0)' ) {
					el.style.color = `${color} !important`;
				}

				// If has border color, make it important.
				if ( borderColor !== 'rgba(0, 0, 0, 0)' ) {
					el.style.borderColor = `${borderColor} !important`;
				}

				// if has children, apply recursively.
				if ( el.childNodes && el.childNodes.length ) {
					el.childNodes.forEach(child => {
						ignoreRecursively(child);
					});
				}
			}

			ignoreRecursively(element);
		});

		return this;
	}

    /**
     * Handle includes
     */
    handleIncludes(){
        const includedElements = document.querySelectorAll( wp_dark_mode_json.options.excludes_elements_includes || '.wp_dark_mode_include' );

		if ( ! includedElements || ! includedElements.length) return;

        includedElements.forEach(element => {
            // Add class .wp-dark-mode-include recursively
            const includeRecursively = el => {

                // Bail, if element is not valid.
                if ( ! el ) return;

                // If el not intance of html.
                if ( ! (el instanceof HTMLElement) ) {
                    return;
                }

                // Add class.
                el.classList.add('wp-dark-mode-include');
                // Remove class.
                el.classList.remove('wp-dark-mode-ignore');

                // if has children, apply recursively.
                if ( el.childNodes && el.childNodes.length ) {
                    el.childNodes.forEach(child => {
                        includeRecursively(child);
                    });
                }
            }

            includeRecursively(element);
        });
    }


    /**
     * isRGB
     *
     * @param {string} color
     * @return {boolean}
     */
    isRGB = color => {
        return color.indexOf('rgb') !== -1;
    }

    /**
     * Hex to RGB
     *
     * @param {string} colorString
     * @param {number} alpha
     * @return {string}
     */
    hexToRGB = (colorString, alpha = 1) => {

        if ( this.isRGB (colorString) ) return colorString

        // Create a temporary div element to apply the color as a style
        const tempDiv = document.createElement("div");
        tempDiv.style.color = colorString;

        // Get the computed RGBA value
        const computedColor = window.getComputedStyle(tempDiv).color;

        // Parse the RGBA values
        const rgbaValues = computedColor.match(/(\d+), (\d+), (\d+), (\d+(\.\d+)?)\)/);

        if (rgbaValues && rgbaValues.length === 6) {
            const red = parseInt(rgbaValues[1]);
            const green = parseInt(rgbaValues[2]);
            const blue = parseInt(rgbaValues[3]);
            const opacity = alpha !== undefined ? alpha : parseFloat(rgbaValues[4]);

            return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
        } else {
            // console.error(`Invalid color string: ${colorString}`);
            return null;
        }
    }


    /**
     * Get White Percentage
     *
     * @param {string} color
     * @return {boolean}
     */
    luminance = color => {
        // Convert hex to RGB if the color is hex
        if (!this.isRGB(color)) {
            color = this.hexToRgb(color);
        }

        // Remove spaces from the color
        color = color.replace(/ /g, '');

        // Split the color into array
        color = color.split(',');
        const r = color[0];
        const g = color[1];
        const b = color[2];

        // Calculate the luminance
        return (0.299 * r + 0.587 * g + 0.114 * b);
    }

    /**
     * Is Dark Color
     * 
     *
     * @param {string} color
     * @return {boolean}
     */
    isDarkColor = color => {
        // Log('luminance', this.luminance(color))
        return this.luminance(color) < 190;
    }

    /**
     * Get White Percentage
     *
     * @param {string} color
     * @return {number}
     */
    getWhitePercentage = color => {
        // Convert hex to RGB if the color is hex
        if (!this.isRGB(color)) {
            color = this.hexToRgb(color);
        }

        // Remove spaces from the color
        color = color.replace(/ /g, '');

        // Split the color into array
        color = color.split(',');
        const r = color[0];
        const g = color[1];
        const b = color[2];

        // Calculate the white percentage
        return Math.round(((255 - r) + (255 - g) + (255 - b)) / 7.65);
    }

    /**
     * Is Transparent
     *
     * @param {string} color
     * @return {boolean}
     */
    isTransparent = color => {

        // Bail, if color not set
        if (!color) return true

        let rgb = this.hexToRGB(color)

        if (!rgb) return true

        // Bail, if color is transparent
        if (rgb == 'rgba(0, 0, 0, 0)') return true;

        if (rgb == 'rgb(0, 0, 0)') return true;

        if (rgb == 'none') return true;

        // Remove spaces from the color
        rgb = rgb.replace(/ /g, '');

        // Split the color into array
        rgb = rgb.split(',');

        // Get the alpha value
        const alpha = parseFloat(rgb[3]);

        // Return true if the alpha value is 0
        if (alpha == 0) return true;
        

        return false
    }

    /**
     * Should Ignore.
     *
     * @param element 
     * @return bool
     */
    shouldIgnore = element => {
        // Return true is element is null
        if (!element) return true;

        const backgroundColor = window.getComputedStyle(element).backgroundColor || 'rgba(0, 0, 0, 0)';

        // Return true if the element has class wp-dark-mode-ignore
        if (element.classList.contains('wp-dark-mode-ignore')) return true;

        // Return true if the element has class wp-dark-mode-switch
        if (element.classList.contains('wp-dark-mode-switch')) return true;

        // Return true if the element has class wp-dark-mode-background
        if (element.classList.contains('wp-dark-mode-background')) return true;

        // Return true if the background color is transparent
        if (this.isTransparent(backgroundColor)) {
            element.classList.add('wp-dark-mode-transparent');
            return true;
        }

        // Has background image.
        if (window.getComputedStyle(element).backgroundImage !== 'none') return true;

        // Is color darkish, return true.
        if (this.isDarkColor(backgroundColor)) return true;

        return false;
    }

}

export default new Color();