import WPDarkMode from "./WPDarkMode";
import Store from "./Store";
import Accessibility from "./Accessibility";
import Color from "./Color";
import Base from "./Base";
import Image from "./Images";
import Video from "./Videos";
import SunCalc from "suncalc";

class DarkModeApp extends Base {

	/**
	 * Events
	 */
	async events() {
		// Sync storages.
		this.syncStorage();

		// Try applying dark mode.
		this.tryApplyingDarkMode();

		// Register event listeners.
		this.registerEvents();

		return this;
	}

	/**
	 * Get user choice.
	 *
	 * @return {string}
	 */
	get hasUserChoice() {
		return null !== Store.get('choice');
	}

	/**
	 * Sync storages
	 */
	syncStorage() {
		/**
		 * Device preference for dark mode
		 */
		Store.set('device', WPDarkMode.isDeviceDark ? 'dark' : 'light');

		/**
		 * These are premium features
		 */
		if (!this.isPremium) return;

		/**
		 * Timezone of device, used for Time based dark mode
		 */
		let timeZoneOffset = (new Date().getTimezoneOffset() / 60);

		// Add + sign if offset is negative. Add - sign if offset is positive.
		if (timeZoneOffset > 0) {
			timeZoneOffset = '-' + timeZoneOffset;
		} else {
			timeZoneOffset = '+' + Math.abs(Number(timeZoneOffset));
		}

		Store.set('timezone', timeZoneOffset);

		/**
		 * Location of device, used for sunset based dark mode
		 * Ask, only if sunset mode is enabled.
		 */
		if (wp_dark_mode_json.options.frontend_mode === 'sunset') {
			this.syncDeviceLocation()
		}
	}

	/**
	 * Update device location
	 */
	async syncDeviceLocation() {

		// Check if location was updated in last 24 hours.
		const locationUpdated = Store.get('location-updated');

		// Bail if location was updated in last 24 hours.
		if (locationUpdated) {
			if (((new Date()) - (new Date().setTime(locationUpdated))) < (24 * 60 * 60 * 1000)) {
				return;
			}
		}

		// Bail, if navigator is not available or geolocation is not supported.
		if (!navigator || !navigator.geolocation) {
			return;
		}

		navigator.geolocation.getCurrentPosition((position) => {
			// Save location for dark mode.
			Store.set('location', position.coords.latitude + ',' + position.coords.longitude);

			// Save location update time.
			Store.set('location-updated', new Date().getTime());

			// Apply sunset mode if it is enabled.
			if ( wp_dark_mode_json.options.frontend_mode === 'sunset' && this.isPremium ) {
				this.applySunsetMode();
			}
		});

	}

	/**
	 * Register event listeners
	 */
	registerEvents() {

		// Adjust presets, colors, exclude elements, etc.
		Color.init();

		Accessibility.init();

		// Replace images.
		Image.init();

		// Replace videos.
		Video.init();

		// Track device settings.
		this.followDevice();
	}

	/**
	 * Listen for device dark mode change
	 */
	followDevice() {
		// Bail, if dark mode is not following device mode.
		if (wp_dark_mode_json.options.frontend_mode !== 'device') return;

		// Bail, if device mode change is not supported.
		if (!window || !window.matchMedia) {
			return;
		}

		const mediaQuery = matchMedia('(prefers-color-scheme: dark)');

		if (!mediaQuery) return false;

		const checkSystemMode = () => {
			// React to device preference change.
			if (mediaQuery.matches) {
				WPDarkMode.activate();
			} else {
				WPDarkMode.deactivate();
			}
		}

		mediaQuery.addEventListener('change', function () {

			// Update user device preference.
			Store.set('device', mediaQuery.matches ? 'dark' : 'light');

			// React to device preference change.

			// Bail, if user choice is set.
			if (Store.get('choice') !== null) {
				return;
			}

			checkSystemMode()
		});

		// Check system mode on load.
		if (!WPDarkMode.isActive && !this.hasUserChoice) {
			checkSystemMode()
		}
	}

	/**
	 * Try applying dark mode.
	 *
	 * @return {Promise<void>}
	 * @private
	 */
	async tryApplyingDarkMode() {

		return new Promise(async (resolve) => {

			// Bail, if caching is not excluded.
			const res = await fetch( wp_dark_mode_json.url.ajax + '?action=wp_dark_mode_options&nonce=' + wp_dark_mode_json.nonce)
			const data = await res.json()

			if ( ! this.isTrue( data.data.performance_exclude_cache ) ) {
				resolve(true);
				return;
			}

			// Bail, if current page is excluded from dark mode.
			if ( ! this.isTrue( wp_dark_mode_json.options.frontend_enabled ) ) {
				resolve(true);
				return;
			}
			
			// Apply dark mode, if user choice is set.
			if (this.hasUserChoice) {
				if ( 'dark' == Store.get('choice') ) {
					WPDarkMode.activate();
				} else {
					WPDarkMode.deactivate();
				}

				resolve(true);
				return;
			}

			// Check and apply selected mode.
			switch (wp_dark_mode_json.options.frontend_mode) {
				case 'default_light':
					WPDarkMode.deactivate();
					break;
				default:
				case 'default':
					WPDarkMode.activate();
					break;
				case 'device':

					if (WPDarkMode.isDeviceDark) {
						WPDarkMode.activate();
					} else {
						WPDarkMode.deactivate();
					}

					break;
				case 'time':

					// Bail, if user is not premium.
					if (!this.isPremium) {
						resolve(true);
						return;
					}

					const starts = wp_dark_mode_json.options.frontend_time_starts;
					const ends = wp_dark_mode_json.options.frontend_time_ends;

					// Helper function to convert "hh:mm AA" format to minutes since midnight
					function timeToMinutes(time) {
						const [timePart, period] = time.split(' ');
						let [hours, minutes] = timePart.split(':').map(Number);

						// Convert to 24-hour format
						if (period === 'PM' && hours !== 12) {
							hours += 12;
						} else if (period === 'AM' && hours === 12) {
							hours = 0;
						}

						return hours * 60 + minutes;
					}

					// Get the current time in minutes since midnight
					const now = new Date();
					const currentMinutes = now.getHours() * 60 + now.getMinutes();

					const startMinutes = timeToMinutes(starts);
					const endMinutes = timeToMinutes(ends);

					let isDarkMode = false;

					// Check if current time is between start and end time
					if (startMinutes < endMinutes) {
						// Time range is within the same day
						if (currentMinutes >= startMinutes && currentMinutes < endMinutes) {
							isDarkMode = true;
						}
					} else {
						// Time range spans across midnight
						if (currentMinutes >= startMinutes || currentMinutes < endMinutes) {
							isDarkMode = true;
						}
					}

					if (isDarkMode) {
						WPDarkMode.activate();
					} else {
						WPDarkMode.deactivate();
					}

					break;
				case 'sunset':

					// Bail, if user is not premium.
					if (!this.isPremium) {
						resolve(true);
						return;
					}

					await this.applySunsetMode();

					break;
			}

			resolve(true)

		});
	}

	async applySunsetMode() {
		// Get sunset time.
		const loc = Store.get('location').split(',').map(Number);
		let { sunrise, sunset } = SunCalc.getTimes(new Date(), loc[0], loc[1]);
		
		// Get current time.
		const now = new Date();

		// Check if it is night time.
		const isNight = now > sunset || now < sunrise;

		// Apply dark mode, if it is night time.
		if (isNight) {
			WPDarkMode.activate();
		} else {
			WPDarkMode.deactivate();
		}
	}

}

export default new DarkModeApp();