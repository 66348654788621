import DarkModeApp from '@common/app.js';
import DarkModeSwitch from '@common/Switch.js';
import { Log } from '@common/Utility.js'; 
/**
 * Initialize the app
 *
 * These classes can be initialized only once to avoid conflicts
 */

(() => {
    window.Log = Log;

    window.WPDarkModeApp = DarkModeApp.init();

    // Initialize the switch.
    DarkModeSwitch.init();
})()